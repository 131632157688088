import React from 'react';
import { Card, Col, Row, CardTitle, Button, CardText, ListGroup, ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileSignature, faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { Loading } from 'app/shared/util/loading';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { TextFormat } from 'react-jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';

export default class Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      fetchedSubscription: false,
      subscription: null
    };
  }

  componentDidMount() {
    this.fetchSubscription();
  }

  componentWillUnmount() {}

  fetchSubscription = () => {
    this.setState({ loading: true, fetchedSubscription: false });
    axios.get('/api/subscriptions/my').then(res => {
      this.setState({ loading: false, fetchedSubscription: true, subscription: res.data });
    }).catch(err => {
      toast.error('Something went wrong! Please try again later.');
      this.setState({ loading: false });
    });
  };

  getPlanName = planNumber => {
    if (planNumber === 0) {
      return 'Free';
    } else if (planNumber === 1) {
      return 'Seed';
    } else if (planNumber === 2) {
      return 'Grow';
    } else if (planNumber === 3) {
      return 'Entreprise';
    }

    return 'Unknown';
  }

  render() {
    const { loading, fetchedSubscription, subscription } = this.state;
    const stripe = "<stripe-pricing-table pricing-table-id='prctbl_1O0h6CFR8SUOs8RnshHeBqyh' "
      + "publishable-key='pk_live_51HGSidFR8SUOs8RnDexd7Ju0uTc5FtYJ4zL60NeLmuyca1bYkrg2UCOe16TbrNKWeSV6MlThvVqXu63ANGjToe2P005daqwyBk'></stripe-pricing-table>";
    console.log(subscription);
    return (
      <div className="main-centered-page">
          {loading && <Loading />}
          { fetchedSubscription && subscription ?
          <>
            <Row>
              <Col />
              <Col>
                <h4>Your current plan is: { this.getPlanName(subscription.planNumber) }</h4>
              </Col>
              <Col />
              <Col />
              <Col />
            </Row>
            <div className="mt-5" />
            <div dangerouslySetInnerHTML={{ __html: stripe }} />
          </> : null }
      </div>
    );
  }
}
