import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Alert, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { getUrlParameter } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getEntity, updateEntity, createEntity, deleteEntity, undeleteEntity, reset, setDatabaseId, setTeamId, setTeamHost } from './application.reducer';
import { IApplication } from 'app/shared/model/application.model';
// tslint:disable-next-line:no-unused-variable
import { mapIdList } from 'app/shared/util/entity-utils';
import { Loading } from 'app/shared/util/loading';
import axios from 'axios';
import { MySnapshots } from '../snapshot/my-snapshots';
import { MyLicenses } from '../license/my-licenses';
import { MyDeployments } from '../deployment/my-deployments';
import ApplicationBasicInfo from './application-basic-info';
import { confirmAlert } from 'react-confirm-alert';
import { Tabs, Tab, Grid, Card } from 'tabler-react';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

export interface IApplicationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IApplicationUpdateState {
  isNew: boolean;
  userId: string;
  baseUrl: string;
  teamId: string;
  ninoxLoading: boolean;
  ninoxFetchedDatabases: boolean;
  ninoxFetchedTeams: boolean;
  ninoxDatabases: any;
  ninoxTeams: any;
  ninoxError: boolean;
  ninoxErrorMessage: string;
  isRevealPassword: boolean;
  tab: number;
}

export class ApplicationUpdate extends React.Component<IApplicationUpdateProps, IApplicationUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentDidMount() {
    const tab = getUrlParameter('t', this.props.location.search);
    if (tab) {
      this.setState({ tab: parseInt(tab, 10) });
    } else {
      this.setState({ tab: 1 });
    }
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  //  handleClose = () => {
  //    this.props.history.push('/entity/application/' + this.props.applicationEntity + '/edit');
  //  };

  render() {
    const { applicationEntity, updating } = this.props;
    const {
      isNew,
      tab
    } = this.state;

    let tabName = null;
    if (tab && tab === 1) {
      tabName = 'Snapshots';
    } else if (tab && tab === 2) {
      tabName = 'Licenses';
    } else if (tab && tab === 3) {
      tabName = 'Deployments';
    } else if (tab && tab === 4) {
      tabName = 'Settings';
    }

    return (
      <div className="main-centered-page">
        <Row>
          <Col>
            <h2 id="gitnoxApp.application.home.createOrEditLabel">
              {isNew || !applicationEntity || !applicationEntity.databaseId ? 'Add a new Application' : applicationEntity.name}
            </h2>
            { applicationEntity && applicationEntity.archived ? <h4><span className="badge badge-danger">Archived</span></h4> : null }
          </Col>
        </Row>
        { isNew || !applicationEntity || !applicationEntity.databaseId ?
          <ApplicationBasicInfo params={this.props.match.params} isNew={isNew} basicEmptyWorkaround />
          : (tabName ?
          <Tabs initialTab={tabName}>
            <Tab title="Snapshots">
              <Grid.Row cards deck>
                <Grid.Col md={12}>
                  { !isNew && applicationEntity.databaseId ? <MySnapshots applicationId={applicationEntity.id} /> : null }
                  { isNew || !applicationEntity.databaseId ? <Card body="Please select a Ninox app from the settings tab" /> : null }
                </Grid.Col>
              </Grid.Row>
            </Tab>
            <Tab title="Licenses">
              <Grid.Row cards deck>
                <Grid.Col md={12}>
                  { !isNew && applicationEntity.databaseId ? <MyLicenses applicationId={applicationEntity.id} /> : null }
                  { isNew || !applicationEntity.databaseId ? <Card body="Please select a Ninox app from the settings tab" /> : null }
                </Grid.Col>
              </Grid.Row>
            </Tab>
            <Tab title="Deployments">
              <Grid.Row cards deck>
                <Grid.Col md={12}>
                  { !isNew && applicationEntity.databaseId ? <MyDeployments applicationId={applicationEntity.id} /> : null }
                  { isNew || !applicationEntity.databaseId ? <Card body="Please select a Ninox app from the settings tab" /> : null }
                </Grid.Col>
              </Grid.Row>
            </Tab>
            <Tab title="Settings">
              <Grid.Row cards deck>
                <Grid.Col md={12}>
                  <ApplicationBasicInfo params={this.props.match.params} isNew={isNew} basicEmptyWorkaround={false} />
                </Grid.Col>
              </Grid.Row>
            </Tab>
          </Tabs>
          : null
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  applicationEntity: storeState.application.entity,
  loading: storeState.application.loading,
  updating: storeState.application.updating,
  updateSuccess: storeState.application.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  deleteEntity,
  undeleteEntity,
  reset,
  setDatabaseId,
  setTeamId,
  setTeamHost
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationUpdate);
