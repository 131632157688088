import axios from 'axios';
import React from 'react';
import { Row, Col, Alert, Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faUndo, faRecycle, faSync } from '@fortawesome/free-solid-svg-icons';
import { NavLink as Link } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Loading } from 'app/shared/util/loading';
import { Storage } from 'react-jhipster';
import { toast } from 'react-toastify';
import {
  getNinoxLoginUsername,
  getNinoxLoginPassword,
  getNinoxLoginDbPassword,
  clearNinoxLoginInfo,
  isNinoxLoginInfoSaved
} from 'app/shared/util/session-utils';
import { AgGridReact } from 'ag-grid-react';
import BooleanCellRenderer from '../../shared/cellRenderers/booleanCellRenderer';
import PushDeploymentRenderer from '../../shared/cellRenderers/pushDeploymentRenderer';
import DeploymentStatusRenderer from '../../shared/cellRenderers/deploymentStatusRenderer';
import DeploymentTeamDatabaseRenderer from '../../shared/cellRenderers/deploymentTeamDatabaseRenderer';
import { confirmAlert } from 'react-confirm-alert';

export class MyDeployments extends React.Component {
    gridApi;
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        fetchedDeployments: false,
        Deployments: [],
        appliedAll: false
      };
    }

    componentDidMount() {
      window.history.pushState('', '', '/entity/application/' + this.props.applicationId + '/edit?t=3');
      this.fetchDeployments();
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
    }

    onGridSizeChanged(params) {
      this.gridApi.sizeColumnsToFit();
    }

    fetchDeployments = () => {
      this.setState({ loading: true });
      axios.get('/api/deployments/application/' + this.props.applicationId).then(res => {
        this.setState({ loading: false, fetchedDeployments: true, deployments: res.data });
      });
    };

    verifyApplyAll = () => {
      confirmAlert({
        title: 'Push snapshot to all',
        message: 'Are you sure you want to push latest published snapshot to all of the cluster?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.applyAll()
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    applyAll = () => {
      this.setState({ loading: true, appliedAll: true });
      axios
        .post('/api/deployments/apply-all/' + this.props.applicationId)
        .then(res => {
          this.setState({ loading: false });
          toast.success('Deployment process has started');
          this.fetchDeployments();
        })
        .catch(err => {
          console.log(err.response.data);
          this.setState({ loading: false });
          toast.error(err.response.data.title);
        });
    };

    verifyApplyDeployment = deployment => {
      confirmAlert({
        title: 'Push a snapshot',
        message: 'Are you sure you want to push latest published snapshot to ' + deployment.teamName + ' (' + deployment.databaseName + ') ?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.applyDeployment(deployment.id)
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    applyDeployment = did => {
      this.setState({ loading: true });
      console.log('Applying deployment');
      axios
        .post('/api/deployments/apply/' + did)
        .then(res => {
          // this.setState({ loading: false });
          if (res.data.error) {
            toast.error('Deployment could not be applied');
          } else {
            toast.success('Deployment has been applied');
          }
          this.fetchDeployments();
        })
        .catch(err => {
          console.log(err.response.data);
          this.setState({ loading: false });
          toast.error(err.response.data.title);
        });
    };

    verifyDeleteDeployment = deployment => {
      confirmAlert({
        title: 'Remove from cluster',
        message: 'Are you sure you want to remove ' + deployment.teamName + ' (' + deployment.databaseName + ') from this cluster?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.deleteDeployment(deployment.id)
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    deleteDeployment = did => {
      this.setState({ loading: true });
      axios.delete('/api/deployments/' + did).then(res => {
        this.fetchDeployments();
      });
    };

    render() {
      const { applicationId } = this.props;
      const { loading, fetchedDeployments, deployments, appliedAll } = this.state;

      let columnDefs = [];
      let rowData = [];
      if (fetchedDeployments && deployments.length > 0) {
        columnDefs = [
          {
            headerName: ' ',
            field: 'teamId',
            width: 50,
            cellRenderer: 'deploymentStatusRenderer'
          },
          {
            headerName: 'User',
            resizable: true,
            field: 'username'
          },
          {
            headerName: 'Team ID',
            resizable: true,
            field: 'teamName'
          },
          {
            headerName: 'Database ID',
            resizable: true,
            field: 'databaseName'
          },
          {
            headerName: '   ',
            field: '',
            pinned: 'right',
            width: 160,
            cellRenderer: 'pushDeploymentRenderer',
            cellRendererParams: {
              verifyApplyDeployment: this.verifyApplyDeployment,
              verifyDeleteDeployment: this.verifyDeleteDeployment,
              loading
            }
          }
        ];

        rowData = [];
        for (const p of deployments) {
          rowData.push(p);
        }
      }

      return (
        <div>
          <Row>
            <Col className="right">
              {appliedAll ?
                <>
                  <button
                    type="button"
                    onClick={this.fetchDeployments}
                    className="btn btn-primary">
                      <FontAwesomeIcon icon={faSync} />
                      &nbsp;
                  </button>
                  {' '}
                </> : null }
                <button
                  type="button"
                  onClick={this.verifyApplyAll}
                  className="btn btn-primary">
                    <FontAwesomeIcon icon={faUndo} />
                    &nbsp; Apply all
                </button>
            </Col>
          </Row>
          {loading ? <Loading /> : null}
          {fetchedDeployments && deployments.length === 0 ? <Alert className="mt-2" color="warning">You don't have any deployment done yet</Alert> : null}
          {fetchedDeployments && deployments.length > 0 ? (
            <div className="mt-2">
              <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    domLayout={'autoHeight'}
                    pagination
                    paginationPageSize={5}
                    gridOptions = {{
                      onGridReady: params => { this.onGridReady(params); },
                      onGridSizeChanged: params => { this.onGridSizeChanged(params); }}
                    frameworkComponents={{
                      booleanRenderer: BooleanCellRenderer,
                      deploymentStatusRenderer: DeploymentStatusRenderer,
                      pushDeploymentRenderer: PushDeploymentRenderer,
                      deploymentTeamDatabaseRenderer: DeploymentTeamDatabaseRenderer
                    }}
                  />
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  }

const mapStateToProps = ({ none }: IRootState) => ({});

const mapDispatchToProps = {
  getSession
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDeployments);
