import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IApplication } from 'app/shared/model/application.model';
import { getEntities as getApplications } from 'app/entities/application/application.reducer';
import { getEntity, updateEntity, createEntity, reset } from './license.reducer';
import { ILicense } from 'app/shared/model/license.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { Loading } from 'app/shared/util/loading';
import { defaultValue } from '../../shared/model/license.model';
import { toast } from 'react-toastify';

export interface ILicenseUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ILicenseUpdateState {
  isNew: boolean;
  applicationId: string;
}

export class LicenseUpdate extends React.Component<ILicenseUpdateProps, ILicenseUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      applicationId: this.props.match.params.applicationId,
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getApplications();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { licenseEntity } = this.props;
      const entity = {
        ...licenseEntity,
        ...values
      };

      entity.application = {};
      entity.application.id = this.state.applicationId;
      if (this.state.isNew) {
        this.props.createEntity(entity).then(res => {
          this.props.history.push('/entity/application/' + this.state.applicationId + '/edit?t=2');
        }).catch(err => {
          toast.error(err.response.data.detail);
        });
      } else {
        this.props.updateEntity(entity).then(res => {
          this.props.history.push('/entity/application/' + this.state.applicationId + '/edit?t=2');
        }).catch(err => {
          toast.error(err.response.data.detail);
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/license');
  };

  render() {
    const { licenseEntity, applications, loading, updating } = this.props;
    const { isNew, applicationId } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="gitnoxApp.license.home.createOrEditLabel">Create or edit a License</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>
                <Loading />
              </p>
            ) : (
              <AvForm model={isNew ? defaultValue : licenseEntity} onSubmit={this.saveEntity}>
                {!isNew && licenseEntity ? (
                  <>
                    <AvGroup>
                      <Label id="licenseLabel" for="license-license">
                        License
                      </Label>
                      <AvField id="license-license" type="text" name="license" disabled />
                    </AvGroup>
                    { licenseEntity.start ?
                    <>
                      <AvGroup>
                        <Label id="startLabel" for="license-start">
                          Activated on
                        </Label>
                        <AvField id="license-start" type="date" className="form-control" name="start" disabled />
                      </AvGroup>
                      <AvGroup>
                        <Label id="endLabel" for="license-end">
                          Expires on
                        </Label>
                        <AvField id="license-end" type="date" className="form-control" name="end" disabled />
                      </AvGroup>
                    </>
                    : <div>
                        <h5>This license is not activated yet</h5>
                        <br/>
                      </div> }
                  </>
                ) : null }
              {/*
                <AvGroup>
                  <Label id="disabledLabel" check>
                    <AvInput id="license-disabled" type="checkbox" className="form-control" name="disabled" />
                    Disabled
                  </Label>
                </AvGroup>
                </AvGroup>
                      */}
                <AvGroup>
                  <Label id="durationLabel" for="license-duration">
                    Duration (in days)
                  </Label>
                  <AvField id="license-duration" type="number" className="form-control" name="duration" min={1} required />
                </AvGroup>
                <AvGroup>
                  <Label id="maxTeamUsersLabel" for="license-maxTeamUsers">
                    Max team users
                  </Label>
                  <AvField id="license-maxTeamUsers" type="number" className="form-control" name="maxTeamUsers" min={0} required />
                </AvGroup>
                <AvGroup>
                  <Label id="remarkLabel" for="license-remark">
                    Notes
                  </Label>
                  <AvField id="license-remark" type="text" name="remark" />
                </AvGroup>
                <AvGroup>
                    <Label id="devLabel" check>
                    <AvInput id="license-dev" type="checkbox" className="form-control" name="dev" />
                    Dev
                  </Label>
                </AvGroup>
                <div className="mt-6" />
                <AvGroup>
                  <Label id="basicDataLabel" for="license-basic-data">
                    Basic Data <small>(JSON format)</small>
                  </Label>
                  <AvField id="license-basic-data" type="textarea" rows={5} name="basicData" />
                </AvGroup>

                <AvGroup>
                  <Label id="extraDataLabel" for="license-extra-data">
                    Extra Data <small>(JSON format)</small>
                  </Label>
                  <AvField id="license-extra-data" type="textarea" rows={7} name="extraData" />
                </AvGroup>

                <Button tag={Link} id="cancel-save" to={'/entity/application/' + applicationId + '/edit?t=2'} replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  applications: storeState.application.entities,
  licenseEntity: storeState.license.entity,
  loading: storeState.license.loading,
  updating: storeState.license.updating,
  updateSuccess: storeState.license.updateSuccess
});

const mapDispatchToProps = {
  getApplications,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseUpdate);
