import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class PushDeploymentRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="center-children">
        <div className="btn-group flex-btn-group-container">
          {this.props.data && this.props.data !== null && this.props.data.id ? (
            <Row>
              <Col>
                <Button
                  tag={Link}
                  onClick={this.props.verifyApplyDeployment.bind(this, this.props.data)}
                  color="primary"
                  size="sm"
                  disabled={this.props.loading}
                >
                  <FontAwesomeIcon icon={faUndo} /> <span className="d-none d-md-inline">Apply</span>
                </Button>
                {' '}
                <Button
                  tag={Link}
                  onClick={this.props.verifyDeleteDeployment.bind(this, this.props.data)}
                  color="danger"
                  size="sm"
                  disabled={this.props.loading}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} /> <span className="d-none d-md-inline">Kick</span>
                </Button>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
    );
  }
}
