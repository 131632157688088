import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISnapshot, defaultValue } from 'app/shared/model/snapshot.model';

export const ACTION_TYPES = {
  FETCH_SNAPSHOT_LIST: 'snapshot/FETCH_SNAPSHOT_LIST',
  FETCH_SNAPSHOT: 'snapshot/FETCH_SNAPSHOT',
  CREATE_SNAPSHOT: 'snapshot/CREATE_SNAPSHOT',
  UPDATE_SNAPSHOT: 'snapshot/UPDATE_SNAPSHOT',
  DELETE_SNAPSHOT: 'snapshot/DELETE_SNAPSHOT',
  RESET: 'snapshot/RESET',
  SET_LOADING: 'application/SET_LOADING'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISnapshot>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type SnapshotState = Readonly<typeof initialState>;

// Reducer

export default (state: SnapshotState = initialState, action): SnapshotState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SNAPSHOT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SNAPSHOT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SNAPSHOT):
    case REQUEST(ACTION_TYPES.UPDATE_SNAPSHOT):
    case REQUEST(ACTION_TYPES.DELETE_SNAPSHOT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SNAPSHOT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SNAPSHOT):
    case FAILURE(ACTION_TYPES.CREATE_SNAPSHOT):
    case FAILURE(ACTION_TYPES.UPDATE_SNAPSHOT):
    case FAILURE(ACTION_TYPES.DELETE_SNAPSHOT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SNAPSHOT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SNAPSHOT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SNAPSHOT):
    case SUCCESS(ACTION_TYPES.UPDATE_SNAPSHOT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SNAPSHOT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};

const apiUrl = 'api/snapshots';

// Actions

export const getEntities: ICrudGetAllAction<ISnapshot> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SNAPSHOT_LIST,
    payload: axios.get<ISnapshot>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ISnapshot> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SNAPSHOT,
    payload: axios.get<ISnapshot>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ISnapshot> = (username, password, entity) => async dispatch => {
  const finalEntity = {};
  finalEntity.snapshot = entity;
  finalEntity.username = username;
  finalEntity.password = password;

  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SNAPSHOT,
    // payload: axios.post(apiUrl, cleanEntity(entity))
    payload: axios.post(apiUrl, finalEntity)
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISnapshot> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SNAPSHOT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISnapshot> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SNAPSHOT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const setLoading = loading => ({
  type: ACTION_TYPES.SET_LOADING,
  payload: loading
});
