import React, { useState, useEffect } from 'react';
import { getUrlParameter } from 'react-jhipster';

import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Alert, Button, Label } from 'reactstrap';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { IRootState } from 'app/shared/reducers';
import { handleRegister, reset } from './register.reducer';
import { Link } from 'react-router-dom';
import { Loading } from 'app/shared/util/loading';
import ReCAPTCHA from 'react-google-recaptcha';

export interface IRegisterProps {
  registrationSuccess: boolean;
  handleRegister: Function;
}

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overseas: false;
      recaptcha: null;
    };
  }

  componentDidMount() {
    // this.setState({ overseas: false });
  }

  componentWillUnmount() {}

  handleValidSubmit = (event, values) => {
    this.props.handleRegister(values.firstName, values.email, values.firstPassword, this.state.overseas, this.state.recaptcha).then(res => {
      window.location.href = '/login?register=true';
    }).catch(e => {
      if (e.response.data.status === 500) {
        window.location.href = '/register?error=true';
      }
    });
    event.preventDefault();
  };

  onChangeCaptcha = v => {
    this.setState({ recaptcha: v });
  }

  // updatePassword = event => setPassword(event.target.value);

  render() {
    const { registrationSuccess, registrationFailure, errorMessage, loading } = this.props;
    const { overseas, recaptcha } = this.state;

    let error500 = false;
    if (window.location) {
      error500 = getUrlParameter('error', window.location.search) === 'true';
    }

    return (
      <div className="main-centered-page">
        {loading ? <Loading /> : null}
        <Row className="justify-content-center">
          <Col md="8">
            {error500 ? <Alert color="danger">Something wen't wrong, please try again.</Alert> : null}
            {registrationFailure ? <Alert color="danger">{errorMessage}</Alert> : null}
            <h1 id="register-title">Registration</h1>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center">
          <Col md="8">
            <AvForm id="register-form" onValidSubmit={this.handleValidSubmit}>
              {/*
                <AvField
                  name="username"
                  label="Username"
                  placeholder={'Your username'}
                  validate={{
                    required: { value: true, errorMessage: 'Your username is required.' },
                    pattern: { value: '^[_.@A-Za-z0-9-]*$', errorMessage: 'Your username can only contain letters and digits.' },
                    minLength: { value: 1, errorMessage: 'Your username is required to be at least 1 character.' },
                    maxLength: { value: 50, errorMessage: 'Your username cannot be longer than 50 characters.' }
                  }}
                /> */}
              <AvField
                name="firstName"
                label="Name"
                placeholder={'Your full name'}
                type="text"
                validate={{
                  required: { value: true, errorMessage: 'Your name is required.' },
                  minLength: { value: 3, errorMessage: 'Your name is required to be at least 3 characters.' }
                }}
              />
              <AvField
                name="email"
                label="Email"
                placeholder={'Your email'}
                type="email"
                validate={{
                  required: { value: true, errorMessage: 'Your email is required.' },
                  minLength: { value: 5, errorMessage: 'Your email is required to be at least 5 characters.' },
                  maxLength: { value: 254, errorMessage: 'Your email cannot be longer than 50 characters.' }
                }}
              />
              <AvField
                name="firstPassword"
                label="Password"
                placeholder={'Password'}
                type="password"
                // onChange={this.updatePassword}
                validate={{
                  required: { value: true, errorMessage: 'Your password is required.' },
                  minLength: { value: 4, errorMessage: 'Your password is required to be at least 4 characters.' },
                  maxLength: { value: 50, errorMessage: 'Your password cannot be longer than 50 characters.' }
                }}
              />
              {/*
                <PasswordStrengthBar password={password} />
                <AvField
                  name="secondPassword"
                  label="New password confirmation"
                  placeholder="Confirm the new password"
                  type="password"
                  validate={{
                    required: { value: true, errorMessage: 'Your confirmation password is required.' },
                    minLength: { value: 4, errorMessage: 'Your confirmation password is required to be at least 4 characters.' },
                    maxLength: { value: 50, errorMessage: 'Your confirmation password cannot be longer than 50 characters.' },
                    match: { value: 'firstPassword', errorMessage: 'The password and its confirmation do not match!' }
                  }}
                /> */}
              { !registrationSuccess ?
              <ReCAPTCHA
                  // sitekey="6Lf8cqsZAAAAABdZjqjxAQ3lp4dPEJYK1Z6GmkKs"
                  sitekey="6Ledar4ZAAAAAHMZRpht_amgTrVWjARWijImUaRy"
                  onChange={this.onChangeCaptcha}
                /> : null }
              <div className="mt-1">&nbsp;</div>
              <Link to="/login" className="btn btn-secondary">
                Back
              </Link>{' '}
              { !registrationSuccess ?
              <Button id="register-submit" color="primary" type="submit" disabled={loading || !recaptcha}>
                Register
              </Button> : null }
            </AvForm>
            <p>&nbsp;</p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.register.loading,
  registrationSuccess: storeState.register.registrationSuccess,
  registrationFailure: storeState.register.registrationFailure,
  errorMessage: storeState.register.errorMessage
});

const mapDispatchToProps = { handleRegister, reset };
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
