import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { TextFormat } from 'react-jhipster';
export default class DateCellRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <span>{this.props.value ? <TextFormat value={this.props.value} type="date" format={APP_DATE_FORMAT} blankOnInvalid /> : null}</span>;
  }
}
