import { Moment } from 'moment';
import { IApplication } from 'app/shared/model/application.model';

export interface ILicense {
  id?: number;
  start?: Moment;
  end?: Moment;
  duration?: number;
  maxTeamUsers?: number;
  remark?: string;
  disabled?: boolean;
  application?: IApplication;
}

export const defaultValue: Readonly<ILicense> = {
  disabled: false,
  maxTeamUsers: 10
};
