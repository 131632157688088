import axios from 'axios';
import {
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IApplication, defaultValue } from 'app/shared/model/application.model';

export const ACTION_TYPES = {
  FETCH_APPLICATION_LIST: 'application/FETCH_APPLICATION_LIST',
  FETCH_APPLICATION: 'application/FETCH_APPLICATION',
  CREATE_APPLICATION: 'application/CREATE_APPLICATION',
  UPDATE_APPLICATION: 'application/UPDATE_APPLICATION',
  DELETE_APPLICATION: 'application/DELETE_APPLICATION',
  UNDELETE_APPLICATION: 'application/UNDELETE_APPLICATION',
  RESET: 'application/RESET',
  SET_DATABASE_ID: 'application/DATABASE_ID',
  SET_TEAM_ID: 'application/TEAM_ID',
  SET_TEAM_HOST: 'application/TEAM_HOST',
  TEST_WEBHOOK: 'application/TEST_WEBHOOK'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IApplication>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ApplicationState = Readonly<typeof initialState>;

// Reducer

export default (state: ApplicationState = initialState, action): ApplicationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APPLICATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_APPLICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.TEST_WEBHOOK):
    case REQUEST(ACTION_TYPES.CREATE_APPLICATION):
    case REQUEST(ACTION_TYPES.UPDATE_APPLICATION):
    case REQUEST(ACTION_TYPES.DELETE_APPLICATION):
    case REQUEST(ACTION_TYPES.UNDELETE_APPLICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.TEST_WEBHOOK):
    case FAILURE(ACTION_TYPES.FETCH_APPLICATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_APPLICATION):
    case FAILURE(ACTION_TYPES.CREATE_APPLICATION):
    case FAILURE(ACTION_TYPES.UPDATE_APPLICATION):
    case FAILURE(ACTION_TYPES.DELETE_APPLICATION):
    case FAILURE(ACTION_TYPES.UNDELETE_APPLICATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICATION_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APPLICATION):
    case SUCCESS(ACTION_TYPES.UPDATE_APPLICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_APPLICATION):
    case SUCCESS(ACTION_TYPES.UNDELETE_APPLICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.TEST_WEBHOOK):
      return {
        ...state,
        updating: false,
        updateSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.SET_TEAM_ID:
      const appl = state.entity;
      appl.teamId = action.payload;
      return {
        ...state,
        entity: appl
      };
    case ACTION_TYPES.SET_TEAM_HOST:
      const applh = state.entity;
      applh.publicTeamHost = action.payload;
      return {
        ...state,
        entity: applh
      };
    case ACTION_TYPES.SET_DATABASE_ID:
      const app = state.entity;
      app.databaseId = action.payload;
      return {
        ...state,
        entity: app
      };
    default:
      return state;
  }
};

const apiUrl = 'api/applications';

// Actions

export const getEntities: ICrudGetAllAction<IApplication> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_APPLICATION_LIST,
    payload: axios.get<IApplication>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IApplication> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APPLICATION,
    payload: axios.get<IApplication>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IApplication> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_APPLICATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IApplication> = entity => async dispatch => {
  if (entity.baseUrl && entity.baseUrl.endsWith('/')) {
    entity.baseUrl = entity.baseUrl.substring(0, entity.baseUrl.length - 1);
  }
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APPLICATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IApplication> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_APPLICATION,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const undeleteEntity: ICrudDeleteAction<IApplication> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/undelete`;
  const result = await dispatch({
    type: ACTION_TYPES.UNDELETE_APPLICATION,
    payload: axios.get(requestUrl)
  });
  return result;
};

export const testWebhook: ICrudPostAction<IApplication> = (webhookUrl, appId) => async dispatch => {
  const requestUrl = `${apiUrl}/test-webhook`;
  const result = await dispatch({
    type: ACTION_TYPES.TEST_WEBHOOK,
    payload: axios.post(requestUrl, { url: webhookUrl, appId })
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const setTeamId = teamId => ({
  type: ACTION_TYPES.SET_TEAM_ID,
  payload: teamId
});

export const setTeamHost = teamHost => ({
  type: ACTION_TYPES.SET_TEAM_HOST,
  payload: teamHost
});

export const setDatabaseId = dbId => ({
  type: ACTION_TYPES.SET_DATABASE_ID,
  payload: dbId
});
