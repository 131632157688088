import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import HomeAnon from './home-anon';
import HomeAuthenticated from './home-authenticated';
import { Loading } from 'app/shared/util/loading';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account, loading } = props;

  if (account && account.login) {
    console.log('redirect to dashboard');
    props.history.push('/dashboard');
  }

  if (account && !account.login) {
    console.log('redirect to login');
    props.history.push('/login');
  }

  return <div>{!account || loading ? <Loading /> : account.login ? null : <HomeAnon />}</div>;
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  loading: storeState.authentication.loading,
  isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
