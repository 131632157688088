import axios from 'axios';
import React from 'react';
import { Button, Row, Col, Label, Alert, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Loading } from 'app/shared/util/loading';
import { Storage } from 'react-jhipster';
import { toast } from 'react-toastify';
import {
  getNinoxLoginUsername,
  getNinoxLoginPassword,
  getNinoxLoginDbPassword,
  clearNinoxLoginInfo,
  clearNinoxPendingActionFlags,
  isNinoxLoginInfoSaved
} from 'app/shared/util/session-utils';
import { getEntity, updateEntity, createEntity, deleteEntity, undeleteEntity, reset, setDatabaseId, setTeamId, setTeamHost, testWebhook } from './application.reducer';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { confirmAlert } from 'react-confirm-alert';
import { faArchive, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';

export class ApplicationBasicInfo extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        fetchedDeployments: false,
        baseUrl: null,
        teamId: null,
        ninoxLoading: false,
        ninoxFetchedDatabases: false,
        ninoxFetchedTeams: false,
        ninoxError: false,
        ninoxErrorMessage: null,
        ninoxDatabases: [],
        ninoxTeams: [],
        isRevealPassword: false,
        webhookUrl: null,
        gitnoxifying: false
      };
    }

    componentDidMount() {
      if (!this.props.isNew) {
        window.history.pushState('', '', '/entity/application/' + this.props.params.id + '/edit?t=4');
        this.props.getEntity(this.props.params.id).then(res => {
          this.setState({ baseUrl: res.value.data.baseUrl, teamId: res.value.data.teamId, webhookUrl: res.value.data.webhookUrl });
          if (Storage.session.get('ninox.pending') && Storage.session.get('ninox.action') === 'fetchDatabases') {
            this.fetchDatabases();
          } else if (Storage.session.get('ninox.pending') && Storage.session.get('ninox.action') === 'fetchTeams') {
            this.fetchTeams();
          } else if (!this.props.basicEmptyWorkaround && Storage.session.get('ninox.pending') && Storage.session.get('ninox.action') === 'gitnoxify') {
            this.gitnoxify(null);
          } else if (res.value.data.teamId && !res.value.data.databaseId) {
            this.fetchDatabases();
          }
        });
      }
    }

    changeBaseUrl = e => {
      this.props.applicationEntity.baseUrl = e.target.value;
      this.setState({ baseUrl: e.target.value });
    };

    changeTeamId = e => {
      this.props.applicationEntity.teamId = e.target.value;
      this.setState({ teamId: e.target.value });
    };

    prompNinoxLogin = a => {
      if (isNinoxLoginInfoSaved() && a === 'fetchDatabases') {
        this.fetchDatabases();
      } else if (isNinoxLoginInfoSaved() && a === 'fetchTeams') {
        this.fetchTeams();
      } else {
        this.props.updateEntity(this.props.applicationEntity).then(res => {
          window.location.href = '/ninox/cred?action=' + a;
        });
      }
    };

    fetchTeams = () => {
      if (this.state.baseUrl) {
        this.setState({ ninoxLoading: true });
        clearNinoxPendingActionFlags();

        axios
          .post('/api/ninox/list/teams', {
            baseUrl: this.state.baseUrl,
            username: getNinoxLoginUsername(),
            password: getNinoxLoginPassword()
          })
          .then(res => {
            this.setState({ ninoxLoading: false });

            if (!res.data.success) {
              clearNinoxLoginInfo();
              this.setState({ ninoxError: true, ninoxErrorMessage: res.data.errorMessage });
            } else {
              this.setState({ ninoxFetchedTeams: true, ninoxTeams: res.data.data });
            }
          });
      }
    };

    fetchDatabases = () => {
      if (this.state.baseUrl && this.state.teamId) {
        this.setState({ ninoxLoading: true });
        clearNinoxPendingActionFlags();

        axios
          .post('/api/ninox/list/databases', {
            baseUrl: this.state.baseUrl,
            teamId: this.state.teamId,
            username: getNinoxLoginUsername(),
            password: getNinoxLoginPassword()
          })
          .then(res => {
            this.setState({ ninoxLoading: false });

            if (!res.data.success) {
              this.setState({ ninoxError: true, ninoxErrorMessage: res.data.errorMessage });
            } else {
              this.setState({ ninoxFetchedDatabases: true, ninoxDatabases: res.data.data });
            }
          });
      }
    };

    selectNinoxTeam = e => {
      this.setState({ ninoxFetchedTeams: false, ninoxTeams: [], teamId: e.id });
      this.props.setTeamId(e.id);
      this.props.setTeamHost(e.host);
      this.props.setDatabaseId(null);
      this.props.updateEntity(this.props.applicationEntity).then(res => {
        this.fetchDatabases();
      });
    };

    selectNinoxDatabase = e => {
      this.setState({ ninoxFetchedDatabases: false, ninoxDatabases: [] });
      this.props.setDatabaseId(e.id);
      this.props.updateEntity(this.props.applicationEntity);
    };

    saveEntity = (event, errors, values) => {
      values.createdOn = convertDateTimeToServer(values.createdOn);

      if (errors.length === 0) {
        const { applicationEntity } = this.props;
        const entity = {
          ...applicationEntity,
          ...values
        };

        if (this.props.isNew) {
          this.props.createEntity(entity).then(res => {
            window.location.href = '/entity/application/' + res.value.data.id + '/edit';
          });
        } else {
          this.props.updateEntity(entity);
        }
      }
    };

    archiveApp = e => {
      this.props.deleteEntity(this.props.applicationEntity.id).then(res => {
        // window.location.href = '/entity/application/' + res.value.data.id + '/edit';
        window.location.href = '/dashboard';
      });
    };

    unarchiveApp = e => {
      this.props.undeleteEntity(this.props.applicationEntity.id).then(res => {
        window.location.href = '/entity/application/' + res.value.data.id + '/edit?t=4';
        // window.location.href = '/dashboard';
      });
    };

    changeWebhookUrl = e => {
      this.setState({ webhookUrl: e.target.value });
    }

    testWebhook = e => {
      console.log('Testing webhook');
      this.setState({ loading: true });
      console.log(this.state.webhookUrl);
      this.props.testWebhook(this.state.webhookUrl, this.props.applicationEntity.appId);
    };

    gitnoxify = e => {
      console.log('Testing gitnoxify');
      this.setState({ gitnoxifying: true, ninoxLoading: true });
      if (isNinoxLoginInfoSaved()) {
        this.gitnoxifyRaw(getNinoxLoginUsername(), getNinoxLoginPassword(), this.props.params.id);
      } else {
        window.location.href = '/ninox/cred?action=gitnoxify';
      }
    };

    gitnoxifyRaw = (username, password, applicationId) => {
      clearNinoxPendingActionFlags();
      axios
        .post('/api/gitnoxify', {
          applicationId,
          username,
          password
        })
        .then(res => {
          this.setState({ gitnoxifying: false, ninoxLoading: false });
          toast.success('The app is successfully gitnoxified!');
        }).catch(err => {
          this.setState({ gitnoxifying: false, ninoxLoading: false });
          if (err && err.response && err.response.data && err.response.data.title) {
            toast.error(err.response.data.title);
          } else {
            toast.error('An error has occured');
          }
          clearNinoxLoginInfo();
        });
    };

    verifyArchive = () => {
      confirmAlert({
        title: 'Archive your application',
        message: 'Are you sure you want to archive this application?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.archiveApp()
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    toggleRevealPassword = () => {
      this.setState({ isRevealPassword: !this.state.isRevealPassword });
    }

    render() {
      const { isNew, applicationId, applicationEntity, updating } = this.props;
      const {
        ninoxLoading,
        ninoxFetchedDatabases,
        ninoxDatabases,
        ninoxFetchedTeams,
        ninoxTeams,
        ninoxError,
        ninoxErrorMessage,
        isRevealPassword,
        loading,
        gitnoxifying
      } = this.state;

      return (
        <div>
          <Row>
            <Col>
              <div className="mt-2" />
              {ninoxError ? <Alert color="danger">{ninoxErrorMessage}</Alert> : null}
              {loading ? (
                <Loading />
              ) : (
                <AvForm model={isNew ? {} : applicationEntity} onSubmit={this.saveEntity}>
                  <AvGroup>
                    <Label id="nameLabel" for="application-name">
                      Name
                    </Label>
                    <AvField id="application-name" type="text" name="name" value={ applicationEntity ? applicationEntity.name : '' } required />
                  </AvGroup>
                  {isNew ? null : (
                    <div>
                      { applicationEntity && applicationEntity.appId ?
                      <AvGroup>
                        <Label id="databaseIdLabel" for="application-databaseId">
                          Application Unique Identifier
                        </Label>
                        <Row>
                          <Col>
                            <AvField
                              id="application-appId"
                              type="text"
                              name="yyy"
                              value={applicationEntity.appId}
                              disabled
                            />
                          </Col>
                        </Row>
                        <Label id="databaseIdLabel" for="application-databaseId">
                          AppID
                        </Label>
                        <Row>
                          <Col>
                            <AvField
                              id="application-id"
                              type="text"
                              name="zzz"
                              value={applicationEntity.id}
                              disabled
                            />
                          </Col>
                        </Row>
                      </AvGroup> : null }

                      {/* applicationEntity.testingLicense ?
                      <AvGroup>
                        <Label id="databaseIdLabel" for="application-databaseId">
                          Testing License <br/> {' '}<small>You can use this license to check the unpublished snapshots - for dev purposes only</small>
                        </Label>
                        <Row>
                          <Col>
                            <AvField
                              id="application-databaseId"
                              type="text"
                              name="xxx"
                              value={applicationEntity.testingLicense}
                              disabled
                            />
                          </Col>
                        </Row>
                      </AvGroup> : null */}
                      <div className="mt-4" />
                      <AvGroup>
                        <Label id="baseUrlLabel" for="application-baseUrl">
                          Base Url
                        </Label>
                        <AvField
                          id="application-baseUrl"
                          type="text"
                          name="baseUrl"
                          value={ applicationEntity ? applicationEntity.baseUrl : '' }
                          onChange={this.changeBaseUrl}
                          disabled={!applicationEntity || applicationEntity.teamId}
                        />
                        <small>Replace with your private cloud or on premise url if your database is not hosted on ninox's public cloud.</small>
                      </AvGroup>
                      {applicationEntity && applicationEntity.teamId ? (
                        <AvGroup>
                          <Label id="teamIdLabel" for="application-teamId">
                            Team Id
                          </Label>
                          <Row>
                            <Col>
                              <AvField id="application-teamId" type="text" name="teamId" onChange={this.changeTeamId} disabled />
                            </Col>
                            <Col />
                            {/*
                            <Col>
                              <Button
                                tag={Link}
                                className="btn btn-warning"
                                onClick={this.prompNinoxLogin.bind(this, 'fetchTeams')}
                                disabled={ninoxLoading}
                              >
                                <span className="d-none d-md-inline">Change</span>
                              </Button>
                            </Col>
                            */}
                          </Row>
                        </AvGroup>
                      ) : (
                        <div>
                          {ninoxLoading ? <Loading /> : null}
                          <Button
                            tag={Link}
                            color="warning"
                            className="btn"
                            onClick={this.prompNinoxLogin.bind(this, 'fetchTeams')}
                            disabled={ninoxLoading}
                          >
                            <span className="d-none d-md-inline">Choose Team / Database</span>
                          </Button>
                        </div>
                      )}
                      {ninoxFetchedTeams ? (
                        <div className="mt-4">
                          <h5>Choose the team</h5>

                          {ninoxTeams.map((team, i) => (
                            <div key={i}>
                              { i === 0 ? <div className="mt-6" /> : null }
                              <Row className="mt-1">
                                <Col>
                                  {team.name} - {team.id}
                                </Col>
                                <Col>
                                  <Button color="primary" onClick={this.selectNinoxTeam.bind(this, team)}>
                                    select
                                  </Button>
                                </Col>
                              </Row>
                              <hr className="info-divider"/>
                            </div>
                          ))}
                        </div>
                      ) : null}

                      {applicationEntity && applicationEntity.databaseId ? (
                        <AvGroup>
                          <Label id="databaseIdLabel" for="application-databaseId">
                            Database Id
                          </Label>
                          <Row>
                            <Col>
                              <AvField
                                id="application-databaseId"
                                type="text"
                                name="databaseId"
                                value={applicationEntity.databaseId}
                                disabled
                              />
                            </Col>
                            <Col/>
                            {/*
                            <Col>
                              <Button
                                tag={Link}
                                className="btn btn-warning"
                                onClick={t                  his.prompNinoxLogin.bind(this, 'fetchDatabases')}
                                disabled={ninoxLoading}
                              >
                                <span className="d-none d-md-inline">Change</span>
                              </Button>
                            </Col>
                            */}
                          </Row>
                        </AvGroup>
                      ) : applicationEntity && applicationEntity.teamId ? (
                        <div>
                          {ninoxLoading ? <Loading /> : null}
                          {/*
                          <Button
                            tag={Link}
                            className="btn btn-warning"
                            onClick={this.prompNinoxLogin.bind(this, 'fetchDatabases')}
                            disabled={ninoxLoading}
                          >
                            <span className="d-none d-md-inline">Choose Database</span>
                          </Button>
                          */}
                        </div>
                      ) : null}
                      {ninoxFetchedDatabases ? (
                        <div className="mt-4">
                          <h5>Choose the database</h5>

                          {ninoxDatabases.map((db, i) => (
                            <div key={i}>
                              { i === 0 ? <div className="mt-6" /> : null }
                              <Row className="mt-1">
                                <Col>
                                  {db.name} - {db.id}
                                </Col>
                                <Col>
                                  <Button color="primary" onClick={this.selectNinoxDatabase.bind(this, db)}>
                                    select
                                  </Button>
                                </Col>
                              </Row>
                              <hr className="info-divider"/>
                            </div>
                          ))}
                        </div>
                      ) : null}

                      {applicationEntity && applicationEntity.teamId && applicationEntity.databaseId ? (
                        <AvGroup>
                          <Row>
                            <Col>
                              <Button color="warning" id="gitnoxify-entity" onClick={this.gitnoxify} disabled={gitnoxifying}>
                                &nbsp; Gitnoxify
                              </Button>
                            </Col>
                            { gitnoxifying ?
                            <Col>
                              <Loading />
                            </Col> : null }
                          </Row>
                          <Row>
                            <Col>
                              <small>This will add the required tables to your Ninox app.</small>
                            </Col>
                          </Row>
                          <div className="mt-5" />
                          <Label id="databasePasswordLabel" for="application-databasePassword">
                            Database Password <small>(If applicable)</small>
                          </Label>
                          <Row>
                            <Col>
                              <Row>
                                <Col md="11">
                                  <AvField
                                    id="application-databasePassword"
                                    type={isRevealPassword ? 'text' : 'password' }
                                    name="databasePassword"
                                    value={applicationEntity.databasePassword}
                                  />
                                </Col>
                                <Col style={{ paddingTop: '1%' }}>
                                  <span onClick={this.toggleRevealPassword}>
                                    <span>
                                      { isRevealPassword ?
                                        <FontAwesomeIcon icon={faEye} /> :
                                        <FontAwesomeIcon icon={faEyeSlash} />}
                                    </span>
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <small>This password should not change once deployed to your clients.<br/>
                                Make sure the initial password you use is complex enough to avoid deployment problems in the future.</small>
                            </Col>
                          </Row>
                          <div className="mt-5" />
                          <Row>
                            <Col>
                              <Label id="webhookLabel" for="application-webhook">
                                Webhook url
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <AvField id="application-webhook" type="text" name="webhookUrl" onChange={this.changeWebhookUrl} />
                            </Col>
                            <Col>
                              <Button color="warning" id="archive-entity" onClick={this.testWebhook} disabled={updating}>
                                &nbsp; Test
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <small>This will send a POST request that contains event based data to this url.</small>
                            </Col>
                          </Row>
                        </AvGroup>
                      ) : null }

                    <div className="mt-5" />
                      {/*
                      <AvGroup>
                        <Label id="ninoxClientSucessLabel" check>
                          <AvInput id="application-ninoxClientSucess" type="checkbox" className="form-control" name="ninoxClientSucess" />
                          Ninox Client Sucess
                        </Label>
                      </AvGroup>
                        */}
                    </div>
                  )}
                  <Button tag={Link} id="cancel-save" to="/dashboard" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" />
                    &nbsp;
                    <span className="d-none d-md-inline">Back</span>
                  </Button>
                  &nbsp;
                  { !isNew ? applicationEntity && applicationEntity.archived ?
                    <Button color="primary" id="archive-entity" onClick={this.unarchiveApp} disabled={updating}>
                      &nbsp; Unarchive App
                    </Button>
                    :
                    <Button color="primary" id="archive-entity" onClick={this.verifyArchive} disabled={updating}>
                      <FontAwesomeIcon icon={faArchive} />
                      &nbsp; Archive App
                    </Button> : null
                  }
                  &nbsp;
                  <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                    <FontAwesomeIcon icon="save" />
                    &nbsp; Save
                  </Button>
                </AvForm>
              )}
            </Col>
          </Row>
        </div>
      );
    }
  }

  const mapStateToProps = (storeState: IRootState) => ({
    applicationEntity: storeState.application.entity,
    loading: storeState.application.loading,
    updating: storeState.application.updating,
    updateSuccess: storeState.application.updateSuccess
  });

  const mapDispatchToProps = {
    getEntity,
    updateEntity,
    createEntity,
    deleteEntity,
    undeleteEntity,
    reset,
    setDatabaseId,
    setTeamId,
    setTeamHost,
    testWebhook
  };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationBasicInfo);
