import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Alert, Row } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction, Storage, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faPlus } from '@fortawesome/free-solid-svg-icons';

import { IRootState } from 'app/shared/reducers';
import { getEntities, reset } from './home.application.reducer';
import { IApplication } from 'app/shared/model/application.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { Loading } from 'app/shared/util/loading';
import moment from 'moment';
import { Grid, Card, Badge, Table } from 'tabler-react';

export interface IHomeAuthenticatedProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IHomeAuthenticatedState = any;

export class HomeAuthenticated extends React.Component<IHomeAuthenticatedProps, IHomeAuthenticatedState> {
  state: IHomeAuthenticatedState = {
    ...getSortState(this.props.location, 18),
    sort: 'createdOn',
    order: 'desc',
    showArchived: false
  };

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate() {
    if (this.props.updateSuccess) {
      this.reset();
    }
  }

  reset = () => {
    this.props.reset();
    this.setState({ activePage: 1 }, () => {
      this.getEntities();
    });
  };

  handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      this.setState({ activePage: this.state.activePage + 1 }, () => this.getEntities());
    }
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => {
        this.reset();
      }
    );
  };

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order, showArchived } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, showArchived);
  };

  getEntitiesExplicit = showArchived => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, showArchived);
  };

  goToApplication = app => {
    window.location.href = '/entity/application/' + app.id + '/edit?t=1';
  };

  toggleShowArchived = e => {
    const oldShow = this.state.showArchived;
    const newShow = !oldShow;
    console.log(newShow);
    this.setState({ showArchived: newShow }, () => {
      this.reset();
    });
    // this.getEntitiesExplicit(newShow);
  }

  render() {
    const { applicationList, match, loading, totalItems } = this.props;
    const { showArchived } = this.state;
    return (
      <div className="main-centered-page">
        <h2 id="prediction-heading">
          My Applications {!loading && applicationList ? ' (' + applicationList.length + ')' : null}
          { Storage.session.get('subscription').subscription.maxNumberOfApplications === -1 ||
            totalItems < Storage.session.get('subscription').subscription.maxNumberOfApplications ?
            <Link to={'/entity/application/new'} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
              <FontAwesomeIcon icon={faPlus} />
              &nbsp; Add a new Application
            </Link> :
            <>
              <br/><br/>
              <Alert color="warning">Please update your plan in order to create more applications</Alert>
            </> }
        </h2>
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : null}

        {!loading && applicationList && applicationList.length === 0 ? (
          <div className="alert alert-warning">
            You don't have any applications yet. Press on <strong>Add a new Application</strong> to start.
          </div>
        ) : null}

        <br/>
        <input type="checkbox" onChange={ this.toggleShowArchived } disabled={ loading } /> Show archived apps
        <br/><br/>

        <InfiniteScroll
          pageStart={this.state.activePage}
          loadMore={this.handleLoadMore}
          hasMore={this.state.activePage - 1 < this.props.links.next}
          loader={<Loading key={0} />}
          threshold={0}
          initialLoad={false}
        >
          <Grid.Row cards deck>
            {applicationList && applicationList.length > 0
              ? applicationList.map((application, i) => (
                  <Grid.Col md={4} key={`entity-${i}`}>
                    <Card statusColor="dark">
                      <Card.Header>
                        <Card.Title>
                          {application.name}
                        </Card.Title>
                        <Card.Options>
                          <Button color="primary" size="sm" onClick={this.goToApplication.bind(this, application)}>
                            View
                          </Button>
                        </Card.Options>
                      </Card.Header>
                      <Table cards>
                        <Table.Row>
                          <Table.Col>{application.licenses} Licenses</Table.Col>
                          <Table.Col alignContent="right">
                            <Badge color="default">{application.snapshots} snapshots</Badge>
                          </Table.Col>
                        </Table.Row>
                      </Table>
                    </Card>
                  </Grid.Col>
                ))
              : null}
            </Grid.Row>
        </InfiniteScroll>
      </div>
    );
  }
}

const mapStateToProps = ({ application, authentication }: IRootState) => ({
  applicationList: application.entities,
  loading: application.loading,
  totalItems: application.totalItems,
  links: application.links,
  entity: application.entity,
  updateSuccess: application.updateSuccess
});

const mapDispatchToProps = {
  getEntities,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeAuthenticated);
