import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class EnableDisableLicenseRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="center-children">
        <div className="btn-group flex-btn-group-container">
          {this.props.data && this.props.data !== null && this.props.data.id ? (
                  <Button
                    tag={Link}
                    to={'/entity/license/' + this.props.data.id + '/edit/' + this.props.data.application.id + '/application'}
                    color="primary"
                    size="sm"
                    disabled={this.props.loading}
                    style={{ marginRight: 4 }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} /> <span className="d-none d-md-inline">Edit</span>
                  </Button>
          ) : null }
          {this.props.data && this.props.data !== null && this.props.data.id && !this.props.data.disabled ? (
              <Button
                tag={Link}
                onClick={this.props.disableLicense.bind(this, this.props.data.id)}
                color="danger"
                size="sm"
                disabled={this.props.loading}
              >
                <span className="d-none d-md-inline">Disable</span>
              </Button>
          ) : null}

          {this.props.data && this.props.data !== null && this.props.data.id && this.props.data.disabled ? (
            <Button
              tag={Link}
              onClick={this.props.enableLicense.bind(this, this.props.data.id)}
              color="success"
              size="sm"
              disabled={this.props.loading}
            >
              <span className="d-none d-md-inline">Enable</span>
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}
