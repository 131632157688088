import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './license.reducer';
import { ILicense } from 'app/shared/model/license.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ILicenseDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class LicenseDetail extends React.Component<ILicenseDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { licenseEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            License [<b>{licenseEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="start">Start</span>
            </dt>
            <dd>
              <TextFormat value={licenseEntity.start} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="end">End</span>
            </dt>
            <dd>
              <TextFormat value={licenseEntity.end} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="duration">Duration</span>
            </dt>
            <dd>{licenseEntity.duration}</dd>
            <dt>
              <span id="remark">Remark</span>
            </dt>
            <dd>{licenseEntity.remark}</dd>
            <dt>
              <span id="disabled">Disabled</span>
            </dt>
            <dd>{licenseEntity.disabled ? 'true' : 'false'}</dd>
            <dt>Application</dt>
            <dd>{licenseEntity.application ? licenseEntity.application.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/license" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/license/${licenseEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ license }: IRootState) => ({
  licenseEntity: license.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseDetail);
