import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Snapshot from './snapshot';
import SnapshotDetail from './snapshot-detail';
import SnapshotUpdate from './snapshot-update';
import SnapshotDeleteDialog from './snapshot-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new/:applicationId/application`} component={SnapshotUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit/:applicationId/application`} component={SnapshotUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SnapshotDetail} />
      <ErrorBoundaryRoute path={match.url} component={Snapshot} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SnapshotDeleteDialog} />
  </>
);

export default Routes;
