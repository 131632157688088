import React from 'react';
import { Row, Col, Alert, Label, Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileSignature, faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { Loading } from 'app/shared/util/loading';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { TextFormat } from 'react-jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';
import SettingsList from './settings-list';

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      fetchedSettings: false,
      settings: []
    };
  }

  componentDidMount() {
    this.fetchSettings();
  }

  componentWillUnmount() {}

  fetchSettings = () => {
    this.setState({ loading: true, fetchedSettings: false });
    axios.get('/api/settings').then(res => {
      this.setState({ loading: false, fetchedSettings: true, settings: res.data });
    }).catch(err => {
      toast.error('Something went wrong! Please try again later.');
      this.setState({ loading: false });
    });
  };

  addSettings = (event, errors, values) => {
    if (errors.length === 0) {
      this.setState({ loading: true });
      axios.post('/api/settings', values).then(res => {
        this.fetchSettings();
      }).catch(err => {
        toast.error('Something went wrong! Please try again later.');
        this.setState({ loading: false });
      });
    }
  };

  deleteSettings = id => {
    this.setState({ loading: true });
    axios.delete('/api/settings/' + id).then(res => {
      this.fetchSettings();
    }).catch(err => {
      toast.error('Something went wrong! Please try again later.');
      this.setState({ loading: false });
    });
  };

  render() {
    const { loading, fetchedSettings, settings } = this.state;

    return (
      <div className="main-centered-page">
          <AvForm model={{}} onSubmit={this.addSettings}>
              <AvGroup>
                <Row>
                  <Col md="5">
                    <AvField id="name" placeholder="Name" type="text" name="name" required disabled={loading}/>
                  </Col>
                  <Col md="5">
                    <AvField id="value" placeholder="Value" type="textarea" rows={5} name="svalue" required disabled={loading}/>
                  </Col>
                  <Col md="2">
                    <Button color="primary" id="save-entity" type="submit" disabled={loading}>
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp; Add
                    </Button>
                  </Col>
                </Row>
              </AvGroup>
          </AvForm>
          {loading && <Loading />}
          <SettingsList fetched={fetchedSettings} settings={settings} deleteFunc={this.deleteSettings}/>
      </div>
    );
  }
}
