import React from 'react';
import { Row, Col, Alert, NavItem, NavLink, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import moment from 'moment';
import { Loading } from 'app/shared/util/loading';

export default class HomeAnon extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="main-centered-page">
        <h2 className="logo-name">
          gitnox
          <span className="text-primary">.</span>
        </h2>
        {/*
        <p className="lead">Welcome!</p>
        */}
        <div className="mt-5" />
        <Link to="/login" className="btn btn-primary">
          {' '}
          Sign in
        </Link>
      </div>
    );
  }
}
