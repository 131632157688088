import { IApplication } from 'app/shared/model/application.model';

export interface ISnapshot {
  id?: number;
  schema?: string;
  publicRemark?: string;
  privateRemark?: string;
  snapshotVersion?: number;
  ninoxVersion?: number;
  published?: boolean;
  deleted?: boolean;
  application?: IApplication;
}

export const defaultValue: Readonly<ISnapshot> = {
  published: false,
  deleted: false
};
