import { Moment } from 'moment';
import { ISnapshot } from 'app/shared/model/snapshot.model';
import { ILicense } from 'app/shared/model/license.model';
import { IUser } from 'app/shared/model/user.model';

export interface IApplication {
  id?: number;
  name?: string;
  createdOn?: Moment;
  baseUrl?: string;
  teamId?: string;
  publicTeamHost?: string;
  databaseId?: string;
  ninoxClientSucess?: boolean;
  snapshots?: ISnapshot[];
  licenses?: ILicense[];
  user?: IUser;
}

export const defaultValue: Readonly<IApplication> = {
  ninoxClientSucess: false
};
