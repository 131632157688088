import axios from 'axios';
import React from 'react';
import { Row, Col, Alert, Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { NavLink as Link } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Loading } from 'app/shared/util/loading';
import { Storage } from 'react-jhipster';
import { toast } from 'react-toastify';
import {
  getNinoxLoginUsername,
  getNinoxLoginPassword,
  getNinoxLoginDbPassword,
  clearNinoxLoginInfo,
  isNinoxLoginInfoSaved
} from 'app/shared/util/session-utils';
import { AgGridReact } from 'ag-grid-react';
import BooleanCellRenderer from '../../shared/cellRenderers/booleanCellRenderer';
import DateTimeCellRenderer from '../../shared/cellRenderers/dateTimeCellRenderer';
import ApplySnapshotRenderer from '../../shared/cellRenderers/applySnapshotRenderer';
import SnapshotApplyHistoryLinkRenderer from '../../shared/cellRenderers/snapshotApplyHistoryLinkRenderer';
import { confirmAlert } from 'react-confirm-alert';

export class MySnapshots extends React.Component {
  gridApi;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetchedSnapshots: false,
      fetchedSnapshotStats: false,
      snapshots: [],
      showArchived: false,
      snapshotStats: null
    };
  }

  componentDidMount() {
    window.history.pushState('', '', '/entity/application/' + this.props.applicationId + '/edit?t=1');
    this.fetchSnapshots();
    this.fetchSnapshotStats();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onGridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
  }

  fetchSnapshots = () => {
    this.setState({ loading: true });
    axios.get('/api/snapshots/application/' + this.props.applicationId).then(res => {
      this.setState({ loading: false, fetchedSnapshots: true, snapshots: res.data });

      if (Storage.session.get('ninox.pending') && Storage.session.get('ninox.action') === 'applySnapshot') {
        this.applySnapshot(Storage.session.get('snapshot.apply.id'));
      }
    });
  };

  fetchSnapshotStats = () => {
    this.setState({ loading: true });
    axios.get('/api/snapshots/stats/' + this.props.applicationId).then(res => {
      this.setState({ loading: false, fetchedSnapshotStats: true, snapshotStats: res.data });
    });
  };

  applySnapshot = sid => {
    this.setState({ loading: true });
    console.log('Applying snapshot');
    axios
      .post('/api/snapshots/apply', {
        snapshotId: sid,
        username: getNinoxLoginUsername(),
        password: getNinoxLoginPassword(),
        dbPassword: getNinoxLoginDbPassword()
      })
      .then(res => {
        this.setState({ loading: false });
        toast.success('Snapshot has been applied');
      })
      .catch(err => {
        console.log(err.response.data);
        this.setState({ loading: false });
        if (err.response.data.errorKey !== 'db.password') {
          clearNinoxLoginInfo();
        }
        toast.error(err.response.data.title);
      });
  };

  verifyPrompNinoxLogin = snapId => {
    // this.prompNinoxLogin(snapId);

    let snapshot = null;
    for (const s of this.state.snapshots) {
      if (s.id === snapId) {
        snapshot = s;
        break;
      }
    }

    confirmAlert({
      title: 'Apply a snapshot',
      message: 'Are you sure you want to apply Snapshot with id ' + snapshot.id + ' and version ' + snapshot.snapshotVersion + '?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.prompNinoxLogin(snapId)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  prompNinoxLogin = snapId => {
    if (isNinoxLoginInfoSaved()) {
      this.applySnapshot(snapId);
    } else {
      Storage.session.set('snapshot.apply.id', snapId);
      window.location.href = '/ninox/cred?action=applySnapshot&showDbPass=true';
    }
  };

  flipShowArchive = () => {
    this.setState({ showArchived: !this.state.showArchived });
  }

  checkSubscriptionplan = () => {

    const lastSize = this.state.snapshotStats.lastApplicationSnapshotSize;

    if (Storage.session.get('subscription').subscription.maxNumberOfSnapshotsPerApplication !== -1
        && this.state.snapshotStats.numberOfSnapshotsPerApplication >= Storage.session.get('subscription').subscription.maxNumberOfSnapshotsPerApplication) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfMonthlySnapshotsPerApplication !== -1
        && this.state.snapshotStats.numberOfMonthlySnapshotsPerApplication >= Storage.session.get('subscription').subscription.maxNumberOfMonthlySnapshotsPerApplication) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfYearlySnapshotsPerApplication !== -1
        && this.state.snapshotStats.numberOfYearlySnapshotsPerApplication >= Storage.session.get('subscription').subscription.maxNumberOfYearlySnapshotsPerApplication) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfSnapshotsPerAccount !== -1
        && this.state.snapshotStats.numberOfSnapshotsPerAccount >= Storage.session.get('subscription').subscription.maxNumberOfSnapshotsPerAccount) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfMonthlySnapshotsPerAccount !== -1
        && this.state.snapshotStats.numberOfMonthlySnapshotsPerAccount >= Storage.session.get('subscription').subscription.maxNumberOfMonthlySnapshotsPerAccount) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfYearlySnapshotsPerAccount !== -1
        && this.state.snapshotStats.numberOfYearlySnapshotsPerAccount >= Storage.session.get('subscription').subscription.maxNumberOfYearlySnapshotsPerAccount) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxSnapshotSize !== -1
        && lastSize >= Storage.session.get('subscription').subscription.maxSnapshotSize) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxSnapshotSizeTotalPerApplication !== -1
        && (this.state.snapshotStats.snapshotSizeTotalPerApplication + lastSize) >= Storage.session.get('subscription').subscription.maxSnapshotSizeTotalPerApplication) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxSnapshotSizeTotalPerAccount !== -1
        && (this.state.snapshotStats.snapshotSizeTotalPerAccount + lastSize) >= Storage.session.get('subscription').subscription.maxSnapshotSizeTotalPerAccount) {
      return false;
    }

    return true;
  }

  render() {
    const { applicationId } = this.props;
    const { loading, fetchedSnapshots, snapshots, showArchived, fetchedSnapshotStats } = this.state;

    let columnDefs = [];
    let rowData = [];
    if (fetchedSnapshots && snapshots.length > 0) {
      columnDefs = [
        {
          headerName: 'Id',
          field: 'id'
        },
        {
          headerName: 'Title',
          field: 'title'
        },
        {
          headerName: 'Taken on',
          field: 'createdOn',
          cellRenderer: 'dateTimeRenderer'
        },
        {
          headerName: 'Published',
          field: 'published',
          cellRenderer: 'booleanRenderer'
        },
        {
          headerName: '# Applied',
          field: 'snapshotApplyHistoriesCount',
          cellRenderer: 'snapshotApplyHistoryLinkRenderer',
          cellRendererParams: {
            mode: 1
          }
        },
        {
          headerName: '   ',
          field: '',
          pinned: 'right',
          width: 310,
          cellRenderer: 'applySnapshotRenderer',
          cellRendererParams: {
            verifyPrompNinoxLogin: this.verifyPrompNinoxLogin,
            loading
          }
        }
      ];

      rowData = [];
      for (const p of snapshots) {
        if (showArchived || !p.archived) {
          p.applicationId = applicationId;
          rowData.push(p);
        }
      }
    }

    return (
      <div>
        { fetchedSnapshotStats ?
        <Row>
          { this.checkSubscriptionplan() ?
            <Col className="right">
              <Link
                to={'/entity/snapshot/new/' + applicationId + '/application'}
                className="btn btn-primary float-right my-snapshot-button"
                id="jh-create-entity"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp; Take a new Snapshot
              </Link>
            </Col> :
            <>
              <br/><br/>
              <Col>
                <Alert color="warning">Please update your plan in order to create more snapshots</Alert>
              </Col>
            </> }
        </Row> : null }
        <Row>
          <Col>
            <input type="checkbox" value={showArchived} onClick={this.flipShowArchive} /> Show archived
          </Col>
        </Row>
        {loading ? <Loading /> : null}
        {fetchedSnapshots && snapshots.length === 0 ? <Alert color="warning">You don't have any snapshots taken yet</Alert> : null}
        {fetchedSnapshots && snapshots.length > 0 ? (
          <div className="mt-2">
            <div className="ag-theme-alpine">
              <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  domLayout={'autoHeight'}
                  pagination
                  paginationPageSize={5}
                  gridOptions = {{
                    onGridReady: params => { this.onGridReady(params); },
                    onGridSizeChanged: params => { this.onGridSizeChanged(params); }}
                  frameworkComponents={{
                    booleanRenderer: BooleanCellRenderer,
                    applySnapshotRenderer: ApplySnapshotRenderer,
                    dateTimeRenderer: DateTimeCellRenderer,
                    snapshotApplyHistoryLinkRenderer: SnapshotApplyHistoryLinkRenderer
                  }}
                />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ none }: IRootState) => ({});

const mapDispatchToProps = {
  getSession
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MySnapshots);
