import { Storage } from 'react-jhipster';

export const saveNinoxLoginInfoExt = (username, password, dbPassword, pending, action) => {
  Storage.session.set('ninox.pending', pending);
  Storage.session.set('ninox.action', action);
  saveNinoxLoginInfoBasic(username, password, dbPassword);
};

export const saveNinoxLoginInfoBasic = (username, password, dbPassword) => {
  Storage.session.set('ninox.username', username);
  Storage.session.set('ninox.password', password);
  Storage.session.set('ninox.dbPassword', dbPassword);
};

export const clearNinoxLoginInfo = () => {
  Storage.session.set('ninox.pending', false);
  Storage.session.set('ninox.action', null);
  Storage.session.set('ninox.username', null);
  Storage.session.set('ninox.password', null);
  Storage.session.set('ninox.dbPassword', null);
};

export const clearNinoxPendingActionFlags = () => {
  Storage.session.set('ninox.pending', false);
  Storage.session.set('ninox.action', null);
};

export const getNinoxLoginUsername = () => Storage.session.get('ninox.username');

export const getNinoxLoginPassword = () => Storage.session.get('ninox.password');

export const getNinoxLoginDbPassword = () => Storage.session.get('ninox.dbPassword');

export const isNinoxLoginInfoSaved = () => Storage.session.get('ninox.username') && Storage.session.get('ninox.password');
