import React from 'react';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faSign, faSignInAlt, faSignOutAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import appConfig from 'app/config/constants';

export const goToDocumentation = props => {
  window.location = 'https://gitnox.com/documentation';
};

export const goToHome = props => {
  window.location = 'https://gitnox.com/';
};

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/logo-jhipster.png" alt="Logo" className="logo-img" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <span className="brand-title">
      <img src="/content/images/gitnox_logo.png" alt="Logo" className="logo-img" />
    </span>
    {/* <span className="navbar-version">{appConfig.VERSION}</span> */}
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink tag={Link} onClick={goToHome} className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>&nbsp;Home</span>
    </NavLink>
  </NavItem>
);

export const Documentation = props => (
  <NavItem>
    <NavLink tag={Link} onClick={goToDocumentation} className="d-flex align-items-center">
      <FontAwesomeIcon icon={faSignInAlt} />
      <span>&nbsp;Documentation</span>
    </NavLink>
  </NavItem>
);

export const Login = props => (
  <NavItem>
    <NavLink tag={Link} to="/login" className="d-flex align-items-center">
      <FontAwesomeIcon icon={faSignInAlt} />
      <span>&nbsp;Sign in</span>
    </NavLink>
  </NavItem>
);

export const Logout = props => (
  <NavItem>
    <NavLink tag={Link} to="/logout" className="d-flex align-items-center">
      <FontAwesomeIcon icon={faSignOutAlt} />
      <span>&nbsp;Sign out</span>
    </NavLink>
  </NavItem>
);

export const Register = props => (
  <NavItem>
    <NavLink tag={Link} to="/register" className="d-flex align-items-center">
      <FontAwesomeIcon icon={faUserPlus} />
      <span>&nbsp;Register</span>
    </NavLink>
  </NavItem>
);
