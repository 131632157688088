import axios from 'axios';
import React from 'react';
import { Row, Col, Alert, Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { NavLink as Link } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Loading } from 'app/shared/util/loading';
import { getUrlParameter } from 'react-jhipster';
import { toast } from 'react-toastify';
import {
  getNinoxLoginUsername,
  getNinoxLoginPassword,
  getNinoxLoginDbPassword,
  clearNinoxLoginInfo,
  isNinoxLoginInfoSaved
} from 'app/shared/util/session-utils';
import { AgGridReact } from 'ag-grid-react';
import BooleanCellRenderer from '../../shared/cellRenderers/booleanCellRenderer';
import DateTimeCellRenderer from '../../shared/cellRenderers/dateTimeCellRenderer';
import SnapshotApplyHistoryStatusCellRenderer from '../../shared/cellRenderers/snapshotApplyHistoryStatusCellRenderer';
import SnapshotApplyHistoryTimeTakenCellRenderer from '../../shared/cellRenderers/snapshotApplyHistoryTimeTakenCellRenderer';

export class SnapshotApplyHistories extends React.Component {
  gridApi;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetchedSnapshotApplyHistories: false,
      snapshotApplyHistories: [],
      mode: 0
    };
  }

  componentDidMount() {
    this.fetchSnapshotApplyHistories();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onGridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
  }

  fetchSnapshotApplyHistories = () => {
    const mode = getUrlParameter('mode', this.props.location.search);
    const id = getUrlParameter('id', this.props.location.search);

    this.setState({ mode: Number.parseInt(mode, 10) });

    if (Number.parseInt(mode, 10) === 1) {
        this.setState({ loading: true });
        axios.get('/api/snapshot-apply-histories/snapshot/' + id).then(res => {
          this.setState({ loading: false, fetchedSnapshotApplyHistories: true, snapshotApplyHistories: res.data });
        });
    } else if (Number.parseInt(mode, 10) === 2) {
      this.setState({ loading: true });
      axios.get('/api/snapshot-apply-histories/license/' + id).then(res => {
        this.setState({ loading: false, fetchedSnapshotApplyHistories: true, snapshotApplyHistories: res.data });
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { applicationId } = this.props;
    const { loading, fetchedSnapshotApplyHistories, snapshotApplyHistories, mode } = this.state;

    let columnDefs = [];
    let rowData = [];
    if (fetchedSnapshotApplyHistories && snapshotApplyHistories.length > 0) {
      columnDefs = [
        {
          headerName: 'Date',
          field: 'historyDateTime',
          cellRenderer: 'dateTimeRenderer'
        },
        {
          headerName: 'Team ID',
          field: 'teamId'
        },
        {
            headerName: 'Database ID',
            field: 'databaseId'
          },
        {
          headerName: 'Status',
          field: 'status',
          cellRenderer: 'snapshotApplyHistoryStatusCellRenderer'
        },
        {
          headerName: 'Time Taken',
          field: 'duration',
          cellRenderer: 'snapshotApplyHistoryTimeTakenCellRenderer'
        },
        {
          headerName: 'Snapshot ID',
          field: 'snapshot.id'
        },
        {
            headerName: 'License',
            field: 'license.license'
        }
      ];

      rowData = [];
      for (const p of snapshotApplyHistories) {
        p.applicationId = applicationId;
        rowData.push(p);
      }
    }

    return (
      <div className="main-centered-page">
        <Row>
          <Col>
            <h2 style={{ marginBottom: 2 }}>History</h2>
            <div className="mt-3" />
          </Col>
        </Row>
        {loading ? <Loading /> : null}
        {fetchedSnapshotApplyHistories && snapshotApplyHistories.length === 0 ?
          <Alert color="warning">{mode === 2 ? 'No one applied using this license yet' : 'No one applied this snapshot yet'}</Alert>
        : null}
        {fetchedSnapshotApplyHistories && snapshotApplyHistories.length > 0 ? (
          <div className="mt-2">
            <div className="ag-theme-alpine">
              <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  domLayout={'autoHeight'}
                  pagination
                  paginationPageSize={5}
                  gridOptions = {{
                    onGridReady: params => { this.onGridReady(params); },
                    onGridSizeChanged: params => { this.onGridSizeChanged(params); }}
                  frameworkComponents={{
                    booleanRenderer: BooleanCellRenderer,
                    snapshotApplyHistoryStatusCellRenderer: SnapshotApplyHistoryStatusCellRenderer,
                    snapshotApplyHistoryTimeTakenCellRenderer: SnapshotApplyHistoryTimeTakenCellRenderer,
                    dateTimeRenderer: DateTimeCellRenderer
                  }}
                />
            </div>
          </div>
        ) : null}
        <div className="mt-3">
            <Button tag={Link} id="cancel-save" onClick={this.goBack} replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
            </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ none }: IRootState) => ({});

const mapDispatchToProps = {
  getSession
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnapshotApplyHistories);
