import React from 'react';
import { Row, Col, Alert, Label, Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileSignature, faSync } from '@fortawesome/free-solid-svg-icons';
import { Loading } from 'app/shared/util/loading';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { TextFormat } from 'react-jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';

export default class SettingsList extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() { }

    render() {
        const { settings, fetched, deleteFunc } = this.props;

        return (
            <div className="logs-container">
                { fetched && settings && settings.length === 0 ? <Alert color="warning">No settings found for this application</Alert> : null}
                { fetched && settings && settings.length > 0 ? (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    Name
                  </th>
                                <th>
                                    Value
                  </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {settings.map((setting, i) => (
                                <tr key={`entity-${i}`}>
                                    <td><div style={{ maxWidth: 10 }} >{setting.name}</div></td>
                                    <td><div style={{ maxWidth: 100 }} >{setting.svalue}</div></td>
                                    <td className="text-right">
                                        <div className="btn-group flex-btn-group-container">
                                            <Button color="danger" size="sm" onClick={deleteFunc.bind(this, setting.id)}>
                                                <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : null}
            </div>
        );
    }
}
