import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export default class SnapshotApplyHistoryTimeTakenCellRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <span>{this.props.value ? Math.round(Number.parseInt(this.props.value, 10) / 1000) + ' s' : null}</span>;
  }
}
