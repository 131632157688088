import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faCheck, faTimes, faCross } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from 'app/shared/util/loading';

export default class DeploymentTeamDatabaseRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="center-children">
        <div className="btn-group flex-btn-group-container">
          {this.props.data && this.props.data !== null && this.props.data.id && this.props.data.teamName && this.props.data.databaseName ? (
            <>
                { this.props.data.teamName + ' (' + this.props.data.databaseName + ')'}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
