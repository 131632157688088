import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class SnapshotApplyHistoryLinkRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="center-children">
        <div className="btn-group flex-btn-group-container">
          {this.props.data && this.props.data !== null && this.props.data.id ? (
            <>
              <a href={'/entity/snapshot-apply-history?mode=' + this.props.mode + '&id=' + this.props.data.id}>{this.props.value}</a>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
