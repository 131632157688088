import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCamera, faSave } from '@fortawesome/free-solid-svg-icons';
import { IRootState } from 'app/shared/reducers';

import { IApplication } from 'app/shared/model/application.model';
import { getEntities as getApplications } from 'app/entities/application/application.reducer';
import { getEntity, updateEntity, createEntity, reset, setLoading } from './snapshot.reducer';
import { ISnapshot } from 'app/shared/model/snapshot.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { Loading } from 'app/shared/util/loading';
import {
  getNinoxLoginUsername,
  getNinoxLoginPassword,
  clearNinoxLoginInfo,
  clearNinoxPendingActionFlags,
  isNinoxLoginInfoSaved
} from 'app/shared/util/session-utils';
import { toast } from 'react-toastify';

export interface ISnapshotUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISnapshotUpdateState {
  isNew: boolean;
  applicationId: string;
}

export class SnapshotUpdate extends React.Component<ISnapshotUpdateProps, ISnapshotUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      applicationId: this.props.match.params.applicationId,
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    if (Storage.session.get('ninox.pending') && Storage.session.get('ninox.action') === 'createSnapshot') {
      this.props.setLoading(true);
      this.saveEntityRaw(getNinoxLoginUsername(), getNinoxLoginPassword(), Storage.session.get('snapshot.entity'));
    }
    // this.props.getApplications();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { snapshotEntity } = this.props;
      const entity = {
        ...snapshotEntity,
        ...values
      };

      if (this.state.isNew) {
        entity.application = {};
        entity.application.id = this.state.applicationId;
        if (isNinoxLoginInfoSaved()) {
          this.saveEntityRaw(getNinoxLoginUsername(), getNinoxLoginPassword(), entity);
        } else {
          Storage.session.set('snapshot.entity', entity);
          this.props.setLoading(false);
          window.location.href = '/ninox/cred?action=createSnapshot';
        }
      } else {
        this.props.updateEntity(entity).then(res => {
          this.props.history.push('/entity/application/' + this.state.applicationId + '/edit');
        });
      }
    }
  };

  saveEntityRaw = (username, password, entity) => {
    clearNinoxPendingActionFlags();
    if (this.state.isNew) {
      this.props.createEntity(username, password, entity).then(res => {
        this.props.history.push('/entity/application/' + this.state.applicationId + '/edit');
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.title) {
          toast.error(err.response.data.title);
        } else {
          toast.error('An error has occured');
        }
        clearNinoxLoginInfo();
        this.props.history.push('/entity/application/' + this.state.applicationId + '/edit');
      });
    } else {
      this.props.updateEntity(entity);
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/snapshot');
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { snapshotEntity, applications, loading, updating } = this.props;
    const { isNew, applicationId } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="gitnoxApp.snapshot.home.createOrEditLabel">Create or edit a Snapshot</h2>
          </Col>
        </Row>
        {loading || updating ? (
          <Loading />
        ) : (
          <Row className="justify-content-center">
            <Col md="8">
              {loading ? (
                <p>
                  <Loading />
                </p>
              ) : (
                <AvForm model={isNew ? {} : snapshotEntity} onSubmit={this.saveEntity}>
                  {!isNew ? (
                    <AvGroup>
                      <Label id="idLabel" for="snapshot-id">
                        ID
                      </Label>
                      <AvField id="snapshot-id" type="string" className="form-control" name="id" disabled />
                    </AvGroup>
                  ) : null}

                  {!isNew ? (
                    <AvGroup>
                      <Label id="snapshotVersionLabel" for="snapshot-snapshotVersion">
                        Snapshot Version
                      </Label>
                      <AvField id="snapshot-snapshotVersion" type="string" className="form-control" name="snapshotVersion" disabled />
                    </AvGroup>
                  ) : null}

                  {!isNew ? (
                    <AvGroup>
                      <Label id="ninoxVersionLabel" for="snapshot-ninoxVersion">
                        Ninox Version
                      </Label>
                      <AvField id="snapshot-ninoxVersion" type="string" className="form-control" name="ninoxVersion" disabled />
                    </AvGroup>
                  ) : null}

                  <AvGroup>
                    <Label id="titleLabel" for="snapshot-title">
                      Title
                    </Label>
                    <AvField id="snapshot-title" type="text" name="title" />
                  </AvGroup>
                  <AvGroup>
                    <Label id="publicRemarkLabel" for="snapshot-publicRemark">
                      Public Remark
                    </Label>
                    <AvField id="snapshot-publicRemark" type="textarea" rows={5} name="publicRemark" />
                  </AvGroup>
                  <AvGroup>
                    <Label id="privateRemarkLabel" for="snapshot-privateRemark">
                      Private Remark
                    </Label>
                    <AvField id="snapshot-privateRemark" type="text" name="privateRemark" />
                  </AvGroup>
                  <AvGroup>
                      <Label id="publishedLabel" check>
                      <AvInput id="snapshot-published" type="checkbox" className="form-control" name="published" />
                      Published
                    </Label>
                  </AvGroup>
                  <br />
                  {!isNew ? (
                    <AvGroup>
                        <Label id="archivedLabel" check>
                        <AvInput id="snapshot-archived" type="checkbox" className="form-control" name="archived" />
                        Archived
                      </Label>
                    </AvGroup>
                  ) : null}
                  <div className="mt-5" />
                  <Row>
                    <Col md={8}>
                      <Button tag={Link} id="cancel-save" onClick={this.goBack} replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />
                        &nbsp;
                        <span className="d-none d-md-inline">Back</span>
                      </Button>
                      &nbsp;
                      {isNew ? (
                        <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                          <FontAwesomeIcon icon={faCamera} />
                          &nbsp; Take a snapshot
                        </Button>
                      ) : (
                        <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                          <FontAwesomeIcon icon={faSave} />
                          &nbsp; Update
                        </Button>
                      )}
                    </Col>
                    {!isNew && false ? (
                      <Col md={4}>
                        <Button tag={Link} className="float-right" to={'/entity/snapshot/' + snapshotEntity.id + '/delete'} color="danger">
                          <FontAwesomeIcon icon={faArchive} /> <span className="d-none d-md-inline">Archive</span>
                        </Button>
                      </Col>
                    ) : null}
                  </Row>
                </AvForm>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  applications: storeState.application.entities,
  snapshotEntity: storeState.snapshot.entity,
  loading: storeState.snapshot.loading,
  updating: storeState.snapshot.updating,
  updateSuccess: storeState.snapshot.updateSuccess
});

const mapDispatchToProps = {
  getApplications,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setLoading
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnapshotUpdate);
