import React, { useState, useEffect } from 'react';
import { getUrlParameter } from 'react-jhipster';

import { connect } from 'react-redux';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Alert, Button, Label } from 'reactstrap';
import { RouteComponentProps, Link } from 'react-router-dom';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { IRootState } from 'app/shared/reducers';

export interface ILoginModalProps {
  loginError: boolean;
  errorMessage: string;
  handleLogin: Function;
  handleClose: Function;
}

class LoginPagePage extends React.Component<ILoginModalProps> {

  handleSubmit = (event, errors, { username, password, rememberMe }) => {
    const { handleLogin } = this.props;

    if (errors && errors.length > 0) {
      return;
    }

    handleLogin(username, password, rememberMe);
  };

  render() {
    const { loginError, handleClose, errorMessage } = this.props;

    let register = false;
    if (window.location) {
      register = getUrlParameter('register', window.location.search) === 'true';
    }

    let reset = false;
    if (window.location) {
      reset = getUrlParameter('reset', window.location.search) === 'true';
    }

    return (
      <div className="main-centered-page">
        <Row className="justify-content-center">
          <Col md="8">
            { register ? <Alert color="success">Registration done! Please check your email for activation.</Alert> : null }
            { reset ? <Alert color="success">Your password was reset succesfully. Please login with the new password.</Alert> : null }
            { loginError ? <Alert color="danger">{errorMessage}</Alert> : null }
            <h1 id="register-title">Sign in</h1>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center">
          <Col md="8">
            <AvForm onSubmit={this.handleSubmit}>
              <AvField name="username" label="Email" placeholder="Your email" required errorMessage="Email cannot be empty!" autoFocus />
              <AvField
                name="password"
                type="password"
                label="Password"
                placeholder="Your password"
                required
                errorMessage="Password cannot be empty!"
              />
              <Link to="/reset/request">Did you forget your password?</Link>
              <div className="mt-5">&nbsp;</div>
              <Row>
                <Col>
                  <Link to="/register" className="btn btn-warning">
                    Register a new account
                  </Link>
                </Col>
                <Col className="right">
                  <Link to="/" className="btn btn-secondary">
                    Back
                  </Link>{' '}
                  <Button color="primary" type="submit" className="login-button">
                    Sign in
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <p>&nbsp;</p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LoginPagePage;
