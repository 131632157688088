import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faCheck, faTimes, faCross } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from 'app/shared/util/loading';

export default class DeploymentStatusRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="center-children">
        <div className="btn-group flex-btn-group-container">
          {this.props.data && this.props.data !== null && this.props.data.id ? (
            <>
                { this.props.data.applying ? <Loading /> : null }
                { !this.props.data.applying && !this.props.data.error ? <FontAwesomeIcon color="green" icon={faCheck} /> : null }
                { !this.props.data.applying && this.props.data.error ? <FontAwesomeIcon color="red" icon={faTimes} /> : null }
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
