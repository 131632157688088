import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from 'reactstrap';
import { Storage, getUrlParameter } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';

import { IApplication } from 'app/shared/model/application.model';
import { IRootState } from 'app/shared/reducers';
import { saveNinoxLoginInfoBasic, saveNinoxLoginInfoExt } from 'app/shared/util/session-utils';
import axios from 'axios';

export interface IUsernamePasswordDialogProps extends StateProps, DispatchProps, RouteComponentProps {}

export class UsernamePasswordDialog extends React.Component<IUsernamePasswordDialogProps> {
  componentDidMount() {
    console.log('UsernamePassword mounted');
  }

  submit = (event, errors, values) => {
    if (errors.length > 0) {
      return;
    }

    const action = getUrlParameter('action', this.props.location.search);
    const showDbPass = getUrlParameter('showDbPass', this.props.location.search);

    axios
      .post('/api/encrypt', {
        username: values.username,
        password: values.password,
        dbPassword: showDbPass ? values.dbPassword : null
      })
      .then(res => {
        console.log(res);
        saveNinoxLoginInfoExt(res.data.username, res.data.password, res.data.dbPassword, true, action);
        this.handleClose(event);
      });
  };

  handleClose = event => {
    console.log('Closing');
    this.props.history.goBack();
  };

  render() {
    const {} = this.props;

    const showDbPass = null; // getUrlParameter('showDbPass', this.props.location.search);

    return (
      <Modal isOpen toggle={this.handleClose}>
        <AvForm model={{}} onSubmit={this.submit}>
          <ModalHeader>Ninox Credentials</ModalHeader>
          <div className="my-modal-fields">
            <Label>Username:</Label>
            <AvField type="text" name="username" required />
            <Label>Password:</Label>
            <AvField type="password" name="password" required />
            {showDbPass ? (
              <>
                <Label>
                  Database Password:
                  <br />
                  <small>(Optional: only if database is password protected)</small>
                </Label>
                <AvField type="password" name="dbPassword" />
              </>
            ) : null}
          </div>
          <ModalBody>P.S. We do not store this information</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleClose}>
              <FontAwesomeIcon icon="ban" />
              &nbsp; Cancel
            </Button>
            <Button color="success" type="submit">
              Continue
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

const mapStateToProps = ({ application }: IRootState) => ({
  // applicationEntity: application.entity
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsernamePasswordDialog);
