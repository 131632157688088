import axios from 'axios';
import React from 'react';
import { Row, Col, Alert, Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { NavLink as Link } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Loading } from 'app/shared/util/loading';
import { Storage } from 'react-jhipster';
import { toast } from 'react-toastify';
import {
  getNinoxLoginUsername,
  getNinoxLoginPassword,
  getNinoxLoginDbPassword,
  clearNinoxLoginInfo,
  isNinoxLoginInfoSaved
} from 'app/shared/util/session-utils';
import { AgGridReact } from 'ag-grid-react';
import BooleanCellRenderer from '../../shared/cellRenderers/booleanCellRenderer';
import LicenseRenderer from '../../shared/cellRenderers/licenseRenderer';
import LicenseInfoRenderer from '../../shared/cellRenderers/licenseInfoRenderer';
import EnableDisableLicenseRenderer from '../../shared/cellRenderers/enableDisableLicenseRenderer';
import DateCellRenderer from '../../shared/cellRenderers/dateCellRenderer';
import SnapshotApplyHistoryLinkRenderer from '../../shared/cellRenderers/snapshotApplyHistoryLinkRenderer';

export class MyLicenses extends React.Component {
  gridApi;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetchedLicenses: false,
      fetchedLicenseStats: false,
      licenses: [],
      licenseStats: null
    };
  }

  componentDidMount() {
    window.history.pushState('', '', '/entity/application/' + this.props.applicationId + '/edit?t=2');
    this.fetchLicenses();
    this.fetchLicenseStats();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onGridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
  }

  fetchLicenses = () => {
    this.setState({ loading: true });
    axios.get('/api/licenses/application/' + this.props.applicationId).then(res => {
      this.setState({ loading: false, fetchedLicenses: true, licenses: res.data });
    });
  };

  fetchLicenseStats = () => {
    this.setState({ loading: true });
    axios.get('/api/licenses/stats/' + this.props.applicationId).then(res => {
      this.setState({ loading: false, fetchedLicenseStats: true, licenseStats: res.data });
    });
  };

  copyLicense = license => {
    navigator.clipboard.writeText(license);
  };

  enableLicense = licenseId => {
    this.setState({ loading: true });
    axios.get('/api/licenses/enable/' + licenseId).then(res => {
      this.updateLicensesWith(res.data);
      this.setState({ loading: false });
    });
  }

  disableLicense = licenseId => {
    this.setState({ loading: true });
    axios.get('/api/licenses/disable/' + licenseId).then(res => {
      this.updateLicensesWith(res.data);
      this.setState({ loading: false });
    });
  }

  updateLicensesWith = license => {
    const newLicenses = [];
    for (const l of this.state.licenses) {
      if (l.id === license.id) {
        newLicenses.push(license);
      } else {
        newLicenses.push(l);
      }
    }
    this.setState({ licenses: newLicenses });
  }

  checkSubscriptionplan = () => {

    if (Storage.session.get('subscription').subscription.maxNumberOfLicensesPerApplication !== -1
        && this.state.licenseStats.numberOfLicensesPerApplication >= Storage.session.get('subscription').subscription.maxNumberOfLicensesPerApplication) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfLicensesPerAccount !== -1
        && this.state.licenseStats.numberOfLicensesPerAccount >= Storage.session.get('subscription').subscription.maxNumberOfLicensesPerAccount) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfActiveLicensesPerApplication !== -1
        && this.state.licenseStats.numberOfActiveLicensesPerApplication >= Storage.session.get('subscription').subscription.maxNumberOfActiveLicensesPerApplication) {
      return false;
    }

    if (Storage.session.get('subscription').subscription.maxNumberOfActiveLicensesPerAccount !== -1
        && this.state.licenseStats.numberOfActiveLicensesPerAccount >= Storage.session.get('subscription').subscription.maxNumberOfActiveLicensesPerAccount) {
      return false;
    }

    return true;
  }

  render() {
    const { applicationId } = this.props;
    const { loading, fetchedLicenses, licenses, fetchedLicenseStats } = this.state;

    let columnDefs = [];
    let rowData = [];
    if (fetchedLicenses && licenses.length > 0) {
      columnDefs = [
        {
          headerName: 'Info',
          field: '',
          width: 250,
          resizable: true,
          filter: true,
          cellRenderer: 'licenseInfoRenderer'
        },
        {
          headerName: 'License',
          field: 'license',
          width: 420,
          resizable: true,
          filter: true,
          floatingFilter: true
          // cellRenderer: 'licenseRenderer',
          // cellRendererParams: {
          //   copyLicense: this.copyLicense
          // }
        },
        {
          headerName: 'Notes',
          resizable: true,
          filter: true,
          floatingFilter: true,
          field: 'remark'
        },
        {
          headerName: 'Days',
          resizable: true,
          field: 'duration'
        },
        {
          headerName: 'Expires On',
          resizable: true,
          field: 'end',
          cellRenderer: 'dateRenderer'
        },
        {
          headerName: '# Applied',
          field: 'snapshotApplyHistoriesCount',
          cellRenderer: 'snapshotApplyHistoryLinkRenderer',
          cellRendererParams: {
            mode: 2
          }
        },
        {
          headerName: '   ',
          field: '',
          resizable: true,
          pinned: 'right',
          cellRenderer: 'enableDisableLicenseRenderer',
          cellRendererParams: {
            enableLicense: this.enableLicense,
            disableLicense: this.disableLicense,
            loading
          }
        }
      ];

      rowData = [];
      for (const p of licenses) {
        rowData.push(p);
      }
    }

    return (
      <div>
        { fetchedLicenseStats ?
        <Row>
          { this.checkSubscriptionplan() ?
            <Col className="right">
              <Link
                to={'/entity/license/new/' + applicationId + '/application'}
                className="btn btn-primary float-right my-snapshot-button"
                id="jh-create-entity"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp; Create a new License
              </Link>
            </Col> :
            <>
              <br/><br/>
              <Col>
                <Alert color="warning">Please update your plan in order to create more licenses</Alert>
              </Col>
            </> }
        </Row> : null }
        {loading ? <Loading /> : null}
        {fetchedLicenses && licenses.length === 0 ? <Alert color="warning">You don't have any license created yet</Alert> : null}
        {fetchedLicenses && licenses.length > 0 ? (
          <div className="mt-2">
            <div className="ag-theme-alpine">
              <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  domLayout={'autoHeight'}
                  pagination
                  paginationPageSize={40}
                  gridOptions = {{
                    onGridReady: params => { this.onGridReady(params); },
                    onGridSizeChanged: params => { this.onGridSizeChanged(params); }}
                    frameworkComponents={{
                      booleanRenderer: BooleanCellRenderer,
                      licenseInfoRenderer: LicenseInfoRenderer,
                      enableDisableLicenseRenderer: EnableDisableLicenseRenderer,
                      dateRenderer: DateCellRenderer,
                      snapshotApplyHistoryLinkRenderer: SnapshotApplyHistoryLinkRenderer
                    }}
                />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ none }: IRootState) => ({});

const mapDispatchToProps = {
  getSession
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLicenses);
