import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import License from './license';
import LicenseDetail from './license-detail';
import LicenseUpdate from './license-update';
import LicenseDeleteDialog from './license-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new/:applicationId/application`} component={LicenseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit/:applicationId/application`} component={LicenseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LicenseDetail} />
      <ErrorBoundaryRoute path={match.url} component={License} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={LicenseDeleteDialog} />
  </>
);

export default Routes;
