import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class ApplySnapshotRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="center-children">
        <div className="btn-group flex-btn-group-container">
          {this.props.data && this.props.data !== null && this.props.data.id && this.props.data.applicationId && this.props.verifyPrompNinoxLogin ? (
            <>
              <Button
                tag={Link}
                to={'/entity/snapshot/' + this.props.data.id + '/edit/' + this.props.data.applicationId + '/application'}
                color="primary"
                size="sm"
                disabled={this.props.loading}
              >
                <FontAwesomeIcon icon={faPencilAlt} /> <span className="d-none d-md-inline">Edit</span>
              </Button>
              <Button
                tag={Link}
                onClick={this.props.verifyPrompNinoxLogin.bind(this, this.props.data.id)}
                color="primary"
                size="sm"
                disabled={this.props.loading}
                style={{ marginLeft: 4 }}
              >
                <FontAwesomeIcon icon={faUndo} /> <span className="d-none d-md-inline">Rollback</span>
              </Button>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
